import "./error.scss"
import Poster from "../../components/Poster/Poster";
import Credits from "../../components/Credits/Credits";
import { motion } from "framer-motion";
import { staggerHalf, defaultPageFadeInVariants } from "../../motionUtils";
import { useSelector } from "react-redux"
import { selectFavouritesList } from "../../redux/favourites/favourites.selectors"
import SkeletonBanner from "../../components/SkeletonBanner/SkeletonBanner";
import { staggerOne, bannerFadeInLoadSectionVariants, bannerFadeInVariants, bannerFadeInUpVariants } from "../../motionUtils";

import ReactPlayer from 'react-player'
const ErrorPage = () => {
    const favs = useSelector(selectFavouritesList);

    return (
        <motion.div
            className="MyList"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            {favs && favs.length > 0 && (
                <h2 className="MyList__title">Error</h2>
            )}
            <motion.div
                className="MyList__wrp"
                variants={staggerHalf}
                initial="initial"
                animate="animate"
                exit="exit"
            >
               	<motion.section
				variants={bannerFadeInLoadSectionVariants}
				initial='initial'
				animate='animate'
				exit='exit'
				className="Banner__loadsection"
			>
                {/* <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' /> */}
                <ReactPlayer url='https://player.vimeo.com/video/502696604' muted={true} playing={true} controls={true}  />
                <iframe className="full-width-video__autoplay-poster-video lazyloaded" data-video-autoplay="" data-src="https://player.vimeo.com/video/502696604?background=1&amp;autoplay=1&amp;loop=1&amp;muted=1" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen=""></iframe>
				<div className="errored">Oops, an error occurred.</div>
			</motion.section>
            </motion.div>
            <Credits />
        </motion.div>
    )
}

export default ErrorPage