import "./homepage.scss"
import Banner from "../../components/Banner/Banner"
import Row from "../../components/Row/Row"
import Credits from "../../components/Credits/Credits";
import { useRetrieveData } from "../../hooks/useRetrieveData";
import { motion } from "framer-motion";
import { defaultPageFadeInVariants } from "../../motionUtils";
import Scroller from '@enact/ui/Scroller';
import { useState } from "react"
import { FaPlay } from "react-icons/fa";
import { BiInfoCircle } from "react-icons/bi";
import { randomize, truncate } from "../../utils";
import { Link } from "react-router-dom";
import CustomBanner from "../../components/CustomBanner/CustomBanner"

const Homepage = () => {
    const rows = useRetrieveData('movies');
    const [scrollOut, setScrollOut] = useState("");

    
    return (
        <motion.div
            className="Homepage"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <div>
          
            <Scroller direction={"vertical"} onScrollStart={({scrollLeft, scrollTop}) => {
    // do something with scrollLeft and scrollTop
    setScrollOut(`Left: ${scrollLeft}, Top: ${scrollTop}`)
}}
>
               <div>
               <div style={{"color": "white"}}>
                {/* {scrollOut} */}
            </div>
            <CustomBanner />
            {/* <Banner /> */}
            {rows && rows.map(props => (
                <Row key={props.id} {...props} />
            ))}
            <Credits />
      
            </div>
            </Scroller>
            </div>
        </motion.div>
    )
}

export default Homepage
