import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Navbar from "./components/Navbar/Navbar"
import Homepage from "./pages/Homepage/Homepage"
import Movies from "./pages/Movies/Movies"
import TVSeries from './pages/TVSeries/TVSeries';
import Popular from "./pages/Popular/Popular";
import MyList from './pages/MyList/MyList';
import Consuelos from './pages/Consuelos/Consuelos';
import { useState } from 'react';
import Auth from "./pages/Auth/Auth";
import Search from "./pages/Search/Search";
import Category from "./pages/Category/Category";
import ErrorPage from "./pages/Error/Error";

import DetailModal from "./components/DetailModal/DetailModal";
import SplashAnimation from "./components/SplashAnimation/SplashAnimation";
import PlayAnimation from "./components/PlayAnimation/PlayAnimation";
import { selectCurrentUser } from './redux/auth/auth.selectors';
import { selectSearchResults } from "./redux/search/search.selectors";
import { checkUserSession } from "./redux/auth/auth.actions";
import Scroller from '@enact/ui/Scroller';
import { FaPlay } from "react-icons/fa";


import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import useColyseus from "./hooks/useColyseus";
import OtherDetailModal from "./components/DetailModal/OtherDetailModal";

const App = () => {

    const currentUser = useSelector(selectCurrentUser);
    const searchResults = useSelector(selectSearchResults);
    const dispatch = useDispatch();
    const location = useLocation();
    const [currentKey, setCurrentKey] = useState("")
    useEffect(() => {
        dispatch(checkUserSession());

   
        const listener = document.addEventListener("keydown", function(inEvent){
            var message = "<h1>" + "Keycode is <lg_red>" + inEvent.keyCode + "</lg_red></h1>"+ "</br>";
            setCurrentKey(inEvent.keyCode);
        });

        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [dispatch])

    let colyseus = useColyseus("ws://localhost:2567")
    let [room, setRoom] = useState();

    useEffect(() => {
        colyseus.joinOrCreate("tetrolyseus").then(room => {
            console.log(room.sessionId, "joined", room.name);
            setRoom(room);
            room.onMessage("move", (message) => {
                console.log(colyseus.id, "received on", room.name, message);
              });

              room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
              });
              
              room.onStateChange((state) => {
                console.log("the room state has been updated:", state);
              });
        }).catch(e => {
            console.log("JOIN ERROR", e);
        });
    }, [])

    return (
        <div className="App">
            {/* {currentKey} */}
            <Navbar />
                    <DetailModal />

            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    <Route
                        exact
                        path="/"
                    >
                        <Redirect to="/browse" />
                    </Route>
                    <Route
                        path="/splash"
                        component={SplashAnimation}
                    />
                    <Route
                        path="/play"
                        component={PlayAnimation}
                    />
                    <Route
                        path="/search"
                        render={() => currentUser
                            ? (searchResults && <Search results={searchResults}/>)
                            : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/browse"
                        render={() => <Homepage />}
                    />
                    <Route
                        exact
                        path="/browse/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/tvseries"
                        render={() =>  { 
                            // room.send("tom", { row: 5, col: 6 });

                           return <TVSeries /> }}
                    />
                                        <Route
                        exact
                        path="/error"
                        render={() =>  { 
                            // room.send("tom", { row: 5, col: 6 });

                           return <ErrorPage /> }}
                    />
                    <Route
                        exact
                        path="/tvseries/:categoryName"
                        render={(props) => currentUser
                            ? <Category {...props} />
                            : <Redirect to="/login" />}
                    />
                    <Route
                        exact
                        path="/movies"
                        render={() => <Movies />}
                    />
                    <Route
                        exact
                        path="/movies/:categoryName"
                        render={(props) => <Category {...props} />}
                    />
                    <Route
                        exact
                        path="/m"
                        render={(props) => <div>
                            <OtherDetailModal />
                        </div>
                            }
                    />
                    <Route
                        exact
                        path="/popular"
                        render={() =>  <Popular /> }
                    />
                    <Route
                        exact
                        path="/popular/:categoryName"
                        render={(props) => { 
                            
                            return <Category {...props} />}}
                    />
                    <Route
                        exact
                        path="/mylist"
                        render={() => currentUser ? <MyList /> : <Redirect to="/login" />}
                    />
                                     
                     <Route
                        exact
                        path="/denied"
                        render={() => <Consuelos /> }
                    />
                    <Route
                        exact
                        path="/login"
                        render={() => currentUser ? <Redirect to="/splash"/> : <Auth />}
                    />
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </AnimatePresence>

        </div>
    )
}


SwiperCore.use([Navigation, Pagination]);

const App2 = () => {

    let colyseus = useColyseus("ws://localhost:2567")
    let [room, setRoom] = useState();

    useEffect(() => {
        colyseus.joinOrCreate("tetrolyseus").then(room => {
            console.log(room.sessionId, "joined", room.name);
            setRoom(room);
            room.onMessage("move", (message) => {
                console.log(colyseus.id, "received on", room.name, message);
              });

              room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
              });
              
              room.onStateChange((state) => {
                console.log("the room state has been updated:", state);
              });
        }).catch(e => {
            console.log("JOIN ERROR", e);
        });
    }, [])
 


    return <div style={{backgroundColor: "red" }}>
        <Swiper direction="horizontal">
        <div
								className="Banner__button"
                                onClick={() => {
                                    room.send("move", { row: 5, col: 6 });

                                }}
							>
								<FaPlay />
								<span>Button</span>
							</div>
        <SwiperSlide>
             <div style={{height: "200px", backgroundColor: "blue"}}>

            Flex Item 1
        </div>
        </SwiperSlide>
        <SwiperSlide>
             <div style={{height: "200px", backgroundColor: "blue"}}>

            Flex Item 2
        </div>
        </SwiperSlide>
        

    {/* <div style={{height: "3000px", display: "flex", flexDirection: "column", justifyContent: "spaceAround",  }}>
        <div style={{height: "200px", backgroundColor: "blue"}}>

            Flex Item 1
        </div>
        <div style={{height: "200px", backgroundColor: "blue"}}>
            Flex Item 2
        </div>
        <div style={{height: "200px", backgroundColor: "blue"}}>
            Flex Item 3
        </div>
        <div style={{height: "200px", backgroundColor: "blue"}}>
            Flex Item 4
        </div>
        <div style={{height: "200px", backgroundColor: "green"}}>
            Flex Item 5
        </div>
        <div style={{height: "200px", backgroundColor: "green"}}>
            Flex Item 6
        </div>
        <div style={{height: "200px", backgroundColor: "green"}}>
            Flex Item 7
        </div>
    </div> */}
    </Swiper>
    <Swiper direction="vertical">
        <SwiperSlide>
             <div style={{height: "200px", backgroundColor: "blue"}}>

            Flex Item 1
        </div>
        </SwiperSlide>
        <SwiperSlide>
             <div style={{height: "200px", backgroundColor: "blue"}}>

            Flex Item 2
        </div>
        </SwiperSlide>
        </Swiper>
    </div>
}




export default App;
