import { useState, useEffect } from "react"
import * as Colyseus from "colyseus.js";

const useColyseus = wsUrl => {
    var client = new Colyseus.Client('ws://localhost:2567');
    const [isScrolled, setIsScrolled] = useState(false);

  return client
}


export default useColyseus