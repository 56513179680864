import "./consuelos.scss"
import Poster from "../../components/Poster/Poster";
import Credits from "../../components/Credits/Credits";
import { motion } from "framer-motion";
import { staggerHalf, defaultPageFadeInVariants } from "../../motionUtils";
import { useSelector } from "react-redux"
import { selectFavouritesList } from "../../redux/favourites/favourites.selectors"
import Banner from "../../components/Banner/Banner"
import Row from "../../components/Row/Row"
import { useRetrieveData } from "../../hooks/useRetrieveData";

const Consuelos = () => {
    const favs = useSelector(selectFavouritesList);
    const rows = useRetrieveData('movies');
    return (
        <motion.div
            className="MyList"
            variants={defaultPageFadeInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            {favs && favs.length > 0 && (
                <h2 className="MyList__title">Denied</h2>
            )}
            <motion.div
                className="MyList__wrp"
                variants={staggerHalf}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <iframe width="560" height="315" src="https://www.youtube.com/embed/7ojfmL_xSrU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  {/* <Banner type='movies' />
            {rows && rows.map(props => (
                <Row key={props.id} {...props} />
            ))} */}
            </motion.div>
            <Credits />
        </motion.div>
    )
}

export default Consuelos