import "./playAnimation.scss"
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { TADUM_SOUND_URL } from "../../requests";
import ReactPlayer from 'react-player'

const PlayAnimation = () => {

	let history = useHistory();
	const soundRef = useRef(null);
	const handleTadum = () => {
		soundRef.current.currentTime = 0;
		soundRef.current.play();
	}

	useEffect(() => {
		handleTadum();
		// var vid = document.getElementById("myVideo");
		// vid.play()
		// setTimeout(() => {
		// 	history.push('/browse')
		// }, 4200)
	}, [history])

	return (
		<div className='PlayAnimation__wrp'>
			<audio ref={soundRef} src={TADUM_SOUND_URL} />
			<ReactPlayer url='https://player.vimeo.com/video/502696604' muted={true} playing={true} controls={true}   />

			{/* <span className="PlayAnimation__text">
				TOMFLIX
			</span> */}
			{/* <video id="myVideo" src={"tiktokintro.mp4"}></video> */}
		</div>
	)
}

export default PlayAnimation
